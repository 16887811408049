import '@babel/polyfill';
import {sendMail} from './sendMail'

const form = document.querySelector('.form')

if(form){
    form.addEventListener('submit',e=>{
        e.preventDefault()
        const name = document.getElementById('name').value 
        const company = document.getElementById('company').value
        const tel = document.getElementById('tel').value
        const mail = document.getElementById('mail').value
        const sujet = document.getElementById('sujet').value
        const message = document.getElementById('message').value
        document.getElementById('spinner').classList.add('loader')
        document.querySelector('.spinner-content').classList.add('spin-wrapper')
        sendMail(name,company,tel,mail,sujet,message)
    })
}